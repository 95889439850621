body {
  margin: 0px;
  background: #fff;
  visibility: visible;
}

.wrapper {
  overflow: hidden;
}
.App {
  text-align: left;
  font-family: "Montserrat";
  color: #242429;
  line-height: 1.5;
  font-size: 1rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-overlay {
  position: absolute;
  top: 80%;
  left: 30%;
  transform: translate(-50%, -50%);
  text-align: center;
  background: linear-gradient(rgb(245, 245, 245), rgba(255, 255, 252, 1)); 
  padding: 10px; 
  border-radius: 5px; 
  max-width: 80%; 
  border-bottom: none;
}

@keyframes slideFromLeft {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
}

.nav-link{
  color: #23258b;
}

div {
  display: block;
}

.bg-light-blue{
  background-color: #fff;
}

.newGrid_container{
  padding: 10px;
  margin-top: 50px;
}

.chromebook-intro {
  max-width: 1000px;
  margin: 50px auto;
  font-size: 18px;
  padding: 20px;  
  letter-spacing: normal;
  text-align: center;
}

.chromebook-introG {
  margin: 10px auto;
  font-size: 18px;
  padding: 20px;  
  letter-spacing: normal;
  text-align: center;
}

h4, .chromebook-intro h4 p {
  font-size: 35px;
  font-weight: 400;
  line-height: 1.17;
  margin-bottom: 0;
}

.content-p{
  font-size: 16px;
  color: #444956;
  line-height: 24px;
  padding-bottom: 15px;
  text-align: justify;
}
.headingL {
  position: relative;    
  padding-bottom: 10px;  
  color: #333;           
  text-align: left;
}

.headingL::after {
  content: '';
  display: block;         
  width: 4rem;             
  height: 4px;            
  background-color: var(--red-color);
  position: absolute;     
  bottom: 0;              
  left: 0;                
  text-align: left;
}

.headingC {
  position: relative;    
  padding-bottom: 10px;  
  color: #333;           
  text-align: center;
}

.headingC-whtclr{
  position: relative;    
  padding-bottom: 10px;  
  color: #333;           
  text-align: center;
}

.headingC-whtclr::after {
  content: '';
  display: block;
  width: 4rem;
  height: 4px;
  background: #fff;
  position: absolute;
  bottom: 0;  
  left: 50%;
  transform: translateX(-50%);
}

.headingC::after {
  content: '';
  display: block;
  width: 4rem;
  height: 4px;
  background-color: var(--red-color);
  position: absolute;
  bottom: 0;  
  left: 50%;
  transform: translateX(-50%);
}

.sub-headingL {
  position: relative;    
  padding-bottom: 10px;  
  color: #fff;           
  text-align: left;
}

.sub-headingL::after {
  content: '';
  display: block;         
  width: 4rem;             
  height: 4px;            
  background-color: var(--red-color);    
  position: absolute;     
  bottom: 0;              
  left: 0;                
  text-align: left;
}

.main-banner{
  background-image: url('./Website/Images/Office/IMG-20240331-WA0037.jpg');
  width: 100%;
  height: 400px; 
  justify-content: center; 
}

.media-banner{
  background-image: url('./Website/Images/Team/GroupImages/DSC_2836.JPG');
  width: 100%;
  height: 600px; 
  margin-top: 15px;
  justify-content: center; 
}
.banner {
  background-size: cover; 
  background-position: center; 
  display: flex;
  align-items: center; 
  color: white; 
  text-align: center;
  position: relative;
}

.modal-dialog{
  max-width: 600px;
}
.banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.banner-content {
  position: relative;
  z-index: 1; 
}

.main-banner h1 {
  font-size: 2em; 
  margin: 0;
  margin-top: 300px;
  transition: transform 2s ease-out, opacity 2s ease-out;
}

.media-banner h1 {
  font-size: 3em; 
  margin: 0;
  margin-top: 600px;
  transition: transform 2s ease-out, opacity 2s ease-out;
}
.banner h1 {
  animation: moveBottomIn 2s forwards;
}

.banner p {
  font-size: 1.5em; 
  margin: 10px 0 0;
}

.clt-pr{
  margin-top: 4%;
  padding: 2rem;
}

.clt-pr p b i{
  font-size: 25px;
}

.fdiv-row{
  text-align: center;
}
.Hbrand-logo{
  width: 100%;
  height: 100%;
}
.Fbrand-logo{
  width: 12%;
  height: 12%;
}

.hjje-nvbr{
  background-color: #f2f9ff;
}

.achi-row{
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.achi-box {
  width: 35%;
  height: 20%;
  margin: 10px;
  padding: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  flex: 50%;
}

.panel {
  border-radius: 4px;
  padding: 0.2rem;
  flex-wrap: wrap;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  
  &.panel-big-height{
    min-height: 150px;
  }
}

.wrap    { 
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
} 

.card-item {
  margin: 1rem;
  width: 20rem fixed;
  max-width: 100%;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 9px 7px 12px;
  background-color: #fff;
  border-radius: 10px;
  border-color: #d5d5f8 #fff #d5d5f8 #fff;

  &.card-item-big-width{
    min-width: 400px;
  }
}

.card-item p{
  font-size: 12px;
  margin-top: 15px;
  text-align: right;
}

.card-item b{
  font-size: 16px;
}

.chromebook-intro-srv{
  margin-top: 90px;
  /* margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px; */
  font-size: 18px;
  padding: 20px;  
  letter-spacing: normal;
  text-align: center;
}

h2, .chromebook-intro-srv h2 p {
  font-size: 48px;
  font-weight: 400;
  line-height: 1.17;
  margin-bottom: 0;
}

.chromebook-intro-srv p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 0;
  text-align: justify;
}


/* clients choice */
.kskkwy-card{
  display: inline;
  margin: 5px;
  width: 48%;
  max-width: 100%;
  border-radius: 20px;
  height: 140px;
  /* text-align: left; */
  text-align: justify;
  &.item-big-width{
    min-width: 400px;
  }
}

:root {
  --white: #fff;
  --dark-blue1: #03045e;
  --dark-blue2: #0077b6;
  --blue-light1: #00b4d8;
  --blue-light2: #90e0ef;
  --blue-light3: #caf0f8;
  --blue-gray-light1: #f8f9fa;
  --fullwidth: 100%;
  --red-color: #ff433a;
}

.kskkwy-card .hweywy-item {
  padding: 1rem;
  border-radius: 0px 20px 0px 20px;
  height: 140px;
  background-color: #515151;
  box-shadow: rgba(0, 0, 0, 0.1) 9px 7px 12px;
  background-size: 200%;
  color: #fff;
  &.item-big-width{
    min-width: 400px;
  }
} 

.kskkwy-card .hweywy-item p{
  font-size: 13px;
  letter-spacing: .10px;
  color: #fff;
  font-weight: lighter;
  padding-bottom: 15px;
}

.hweywy-item span{
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .media-banner{
    width: 100%;
    height: 250px; 
  }
  .kskkwy-card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .kskkwy-card .hweywy-item {
    padding: 0.5rem;
    border-radius: 10px;
  }
}

/* Events */

.hweyevt-card .hweyevt-item {
  position: relative;
  padding: 1rem;
  margin: 10px;
  height: 110px;
  background-color: #c2bff8;
  border-radius: 10px;
  color: #23258b;
  box-shadow: rgba(0, 0, 0, 0.1) 9px 7px 10px;
  /* background: linear-gradient(to left, #fff 50%, #5A4FCF 50%) right; */
  background-size: 200%;
  
  &.item-big-width{
    min-width: 400px;
  }
} 

.hweyevt-card .hweyevt-item p{
  font-size: 11px;
  letter-spacing: .12px;
  font-weight: lighter;
}

.hweyevt-item span{
  font-weight: 600;
  font-size: 13px;
}

.vertLine {
  content: "";
  background-color: #00335e;
  position: absolute;
  border-radius: 5px;
  width: 15px;
  height: 300px;
  top: 10px;
  left: 1%;
  display: block;
}


/* Team */

.subTeamHeader{
  font-weight: 600;
  font-size: 24px;
  margin: 10px;
}

.subTeamHeaderAlign{
  display: inline-flex; 
  align-items: center;
  justify-content: center;
  text-align: center;
}
.vertLineTeamHeader{
  height: 1rem;
  width: 0.4rem;
  background-color: #ff433a
}
.columnTeam1{
  width: 25%;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
.columnTeam1 .team-9 {
  transform: scale3d(1, 1, 1);
  transition: transform .5s ease-in;
}
.columnTeam1 .team-9:hover{
  transform: scale3d(1.1, 1.1, 1);
  transition: transform .5s ease-out, box-shadow 0.5s;
}

.team-9 {
  height: 100%;
  width: 100%;
  display: flex-row;
  align-items: center;
  justify-content: center;
}

.team-img {
  position: relative;
  font-size: 0;
  text-align: center;
  margin-bottom: 20px;
}

.team-img img {
  width: 80%;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 5%;
}

.team-9 .team-content {
  text-align: center;
}

.team-content h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-content h3 {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.columnTeam2{
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.columnTeam2 .team-10 {
  transform: scale3d(1, 1, 1);
  transition: transform .5s ease-in;
}
.columnTeam2 .team-10:hover{
  transform: scale3d(1.1, 1.1, 1);
  transition: transform .5s ease-out, box-shadow 0.5s;
}

.team-10 {
  height: 80% !important;
  width: 80% !important;
  display: flex-row;
  align-items: center;
  justify-content: center;
}

.team-10 .team-content {
  text-align: center;
}

.team-10 .team-content h2 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-10 .team-content h3 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}

.card {
  /* height: 200px;
  width: 200px; */
  background: #fff;
  border-radius: 50%;
  transition: background 0.8s;
  overflow: hidden;
  background: #000;
  /* box-shadow: 0px 5px 40px 0 #888; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px;
}

.ajsd-contactBtn{
  position:fixed;
	width:100px;
	height:40px;
	bottom:40px;
	right:20px;
  font-size: 13px;
  cursor: pointer;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}


/* Adjust styles for devices with a maximum width of 768px */
@media only screen and (max-width: 768px) {
  .banner h1{
    font-size: 30px;
  }
  .kskkwy-card{
    width: 100%;
  }
  .hweyevt-card{
    width: 100%;
  }
  .hweyevt-item{
    width: 100%;
  }
  .team-itemb{
    height: 250px;
    width: 250px;
  }
  .team-items {
    height: 250px;
    width: 250px;
  }
  .card {
    display: column;
    height: 250px;
    width: 250px;
    margin: 3%; 
  },
  .columnTeam1{
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .team-9 .team-content h2 {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .team-9 .team-content h3 {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .columnTeam2{
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .team-10 .team-content h2 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 5px;
  }
  
  .team-10 .team-content h3 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
}

/* Adjust styles for devices with a maximum width of 480px */
@media only screen and (max-width: 480px) {
  /* .head-top{
    width: 50%;
  } */
  .banner h1{
    font-size: 30px;
  }
  .kskkwy-card{
    width: 100%;
  }
  .hweyevt-card{
    width: 100%;
  }
  .columnTeam1{
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .columnTeam2{
    width: 100%;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
}

@keyframes moveTopIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 10;
  }
}

@keyframes moveBottomIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes moveRightIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveRightOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

.card-box{
  width: 10rem;
}
/* Initial state before animation */
.card-hidden {
  transform: translateX(-10%);
  opacity: 0;
  visibility: hidden;
  transition: transform 1s ease-in-out, opacity 1s ease-out;
}

/* Class to apply the animation */
.card-visible {
  animation: moveRightIn 1s forwards;
  visibility: visible;
}

/* Class to apply the exit animation */
.card-exit {
  animation: moveRightOut 1s forwards;
}

.award-card {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;

}

.award-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.award-image {
  width: 100%;
  height: auto;
}

.award-content {
  padding: 15px;
}

.award-title {
  font-size: 1.25em;
  margin: 0 0 10px;
}

.award-date {
  font-size: 0.9em;
  color: #777;
  margin: 0 0 10px;
}

.award-description {
  font-size: 0.8em;
  color: #555;
}

.awards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media (max-width: 600px) {
  .awards-container {
    grid-template-columns: 1fr;
  }
  ..card-exit{
    animation: none;
  }
}


.footer {
  background-color: #f8f9fa;
  padding: 20px;
  text-align: center;
  color: #333;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-logo img {
  width: 150px;
}

.footer-logo .headingC {
  /* margin-top: 10px; */
  width: 50em;
}

.footer-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.footer-addresses {
  width: 75%;
  margin: 20px 0;
}

.footer-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
}

.footer-contact h3 {
  margin-bottom: 20px;
}

.footer-contact form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.footer-contact input, .footer-contact textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.footer-contact button {
  background-color: #033469;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.footer-contact button:hover {
  background-color: #022a56;
}

.footer-addresses {
  display: flex;
}

.footer-address {
  margin: 30px;
}

.footer-address address {
  margin-bottom: 10px;
  text-align: left;
}

.footer-address address {
  font-size: 15px;
}

.footer-address iframe {
  border: none;
  width: 100%;
  height: 220px;
}

.footer-social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.footer-social a {
  color: #333;
  margin: 0 10px;
  font-size: 1.5em;
  text-decoration: none;
}

.footer-social a:hover {
  color: #033469;
}

.footer-social .nav-link {
  color: #333;
  margin: 0 10px;
  font-size: 1em;
  text-decoration: none;
}

.footer-company-name {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
    align-items: center;
  }

  .footer-contact, .footer-addresses {
    flex-direction: column;
    width: 100%;
  }
}


.achievement {
  background-color: #515151;
  border-radius: 30px;
}

.video-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px;
}

.video-item {
  text-wrap: wrap;
  padding: 10px;
  /* margin: 15px; */
  cursor: pointer;
  /* transition: all 0.2s ease-in-out; */
}

.video-item:hover {
  transform: scale(1.02);
}

.video-thumbnail {
  width: 100%;
  height: 200px;
  object-fit: cover; /* Adjust as needed */
}

.video-title {
  font-weight: bold;
  margin-top: 5px;
}

.selected-video {
  margin-top: 20px;
}
