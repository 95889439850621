.privacy-policy-content {
  background: #fff;
  border-radius: 8px;
  padding: 30px;
  margin: 40px auto;
  text-align: left;
}

.privacy-policy-content h1 {
  font-size: 1.8em;
  margin-bottom: 25px;
  color: #222;
  text-align: center;
}

.privacy-policy-content h2 {
  font-size: 1.4em;
  margin-top: 25px;
  margin-bottom: 15px;
  color: #333;
}

.privacy-policy-content h3 {
  font-size: 1.2em;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #444;
}

.privacy-policy-content p {
  font-size: 0.9em;
  line-height: 1.6;
  color: #555;
  margin-bottom: 15px;
}

.privacy-policy-content ul {
  list-style-type: disc;
  margin-left: 20px;
  color: #555;
}

.privacy-policy-content ul li {
  margin-bottom: 8px;
  font-size: 0.9em;
}

@media (max-width: 768px) {
  .privacy-policy-content {
    padding: 20px;
    margin: 20px;
  }

  .privacy-policy-content h1 {
    font-size: 1.6em;
  }

  .privacy-policy-content h2 {
    font-size: 1.2em;
  }

  .privacy-policy-content h3 {
    font-size: 1em;
  }
}
