.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: left;
    width: 100%;
    max-width: 700px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .modal-content h2 {
    margin-top: 0;
    font-size: 24px;
    color: #333;
  }
  
  .modal-content p {
    font-size: 16px;
    color: #393838;
    margin-bottom: 20px;
  }
  .modal-content ul li {
    font-size: 14px;
    color: #393838;
    margin-bottom: 10px;
  }
  .modal-content button {
    width: 8rem;
    padding: 5px 5px;
    background: var(--dark-blue1);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .modal-content button:hover {
    background: var(--dark-blue1);
  }
  
  /* Media queries for responsive adjustments */
@media (max-width: 768px) {
  .modal-dialog{
    maxWidth: 700px;
  }
  .modal-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal-content {
    padding: 15px;
  }

  .modal-content h2 {
    font-size: 20px;
  }

  .modal-content p,
  .modal-content ul li {
    font-size: 14px;
  }

  .modal-content button {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .modal-dialog{
    maxWidth: 700px;
  }
  .modal-content {
    padding: 10px;
  }

  .modal-content h2 {
    font-size: 18px;
  }

  .modal-content p,
  .modal-content ul li {
    font-size: 12px;
  }

  .modal-content button {
    font-size: 12px;
  }
}

.modal-dialog{
  maxWidth: 700px;
}