:root {
    --navbar-background-color: #fff;
    --navbar-text-color: #333;
    --navbar-hover-color: #007bff;
    --logo-height: 50px;
  }
  
  .head-top {
    background-color: var(--navbar-background-color);
    border-bottom: 1px solid #ddd;
    width: 100%;
  }
  
  .Hbrand-logo {
    height: var(--logo-height);
  }
  
  .navbar-nav .nav-item .nav-link {
    color: var(--navbar-text-color);
    padding: 0.5rem 1rem;
  }
  
  .navbar-nav .nav-item .nav-link:hover {
    color: var(--navbar-hover-color);
  }
  
  .navbar-toggler {
    border: none;
  }
  
  @media (max-width: 767px) {
    .head-top {
        width: 100%;
      }
    .navbar-nav .nav-item .nav-link {
      padding: 0.5rem;
      text-align: center;
    }
  
    .navbar-collapse {
      justify-content: center;
    }
  }
  
  @media (max-width: 576px) {
    .head-top {
        width: 100%;
      }
    .navbar-brand {
      flex-grow: 1;
      text-align: left;
    }
  
    .Hbrand-logo {
        width: 7rem;
        height: 4rem;
    }
  
    .navbar-nav .nav-item {
      width: 100%;
    }
  
    .navbar-nav .nav-item .nav-link {
      padding: 0.5rem 0;
    }
  
    .navbar-collapse {
      justify-content: flex-start;
    }
  }
  